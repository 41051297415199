import OpenAI from 'openai';
//import { Binary } from 'bson';
import { getProjectPrompt } from './GPTPrompts/ProjectPrompt';
import { getKnowledgePrompt } from './GPTPrompts/KnowledgePrompt';
import { getNotesPrompt } from './GPTPrompts/NotesPrompt';
import { FlowDefinitions, GraphReasoning, ContentCreationDefinitions } from './GPTPrompts/FlowDefinitions';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { uploadFiles } from '../AWS/s3Utils'
import { generateMessages } from 'qwanyx2/generateMessages';


const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });
const GPT_MODEL = 'chatgpt-4o-latest'

/*
async function main() {
  const response = await openai.chat.completions.create({
    model: "gpt-4o-mini",
    messages: [
      {
        role: "user",
        content: [
          { type: "text", text: "What’s in this image?" },
          {
            type: "image_url",
            image_url: {
              "url": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Gfp-wisconsin-madison-the-nature-boardwalk.jpg/2560px-Gfp-wisconsin-madison-the-nature-boardwalk.jpg",
            },
          },
        ],
      },
    ],
  });
  console.log(response.choices[0]);
};
*/

/**
 * Helper function to add an image message to the messageStream.
 *
 * @param {Array} messageStream - The existing message stream.
 * @param {string} url - URL of the image to be added.
 * @param {string} detail - (Optional) Detail level of the image.
 */
const addImage = (messageStream, url, detail = "low") => {
  messageStream.push({
    role: "user",
    content: [
      {
        type: "image_url",
        image_url: {
          url: url,
          detail: detail, // Include detail if relevant
        },
      },
    ],
  });
};


/**
 * Helper function to add a text message to the messageStream.
 *
 * @param {Array} messageStream - The existing message stream.
 * @param {string} textContent - The text message to be added.
 */
const addText = (messageStream, textContent, role='user') => {
  messageStream.push({
    role: role,
    content: textContent,
  });
};

/**
 * Secondary helper function to extract a specific image link and add it to the messageStream.
 *
 * @param {Array} messageStream - The existing message stream.
 * @param {string} imageLinks - String containing multiple image links separated by newlines.
 * @param {number} index - (1-based index) The position of the image link to extract from imageLinks.
 * @param {string} baseUrl - Base URL to prepend to the image link.
 * @param {string} detail - (Optional) Detail level of the image.
 */
const addImageLink = (
  messageStream,
  imageLinks,
  index,
  detail = "low",
  baseUrl = "https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com",
) => {

  const imageArray = imageLinks.split("\n"); // Split the image links string by newline
  const extractedImage = imageArray[index - 1]; // Extract the link at the specified (1-based) index
  if (extractedImage) {
    const fullUrl = `${baseUrl}${extractedImage}`; // Construct the full image URL
    addImage(messageStream, fullUrl, detail); // Use addImage helper to add the image to the messageStream
  } else {
    console.warn(
      `Invalid index: ${index}. No image link found at this position in imageLinks.`
    );
  }
};

// Main function
export const getAnswerFromImage = async (imageLinks, prompt, connectedNodes, projectMode = true) => {
  try {
    // Base URL for the images


    // Generate messages using the generateMessages function

    const { messages: messageStream } = generateMessages(connectedNodes);

    // Handle flow definitions (if in project mode)
    const flowDefinitions = projectMode
      ? GraphReasoning()
      : ContentCreationDefinitions();

    // Use helper functions to append messages
    
    addImageLink(messageStream, imageLinks, 1,"low"); // Add the first image link (1-based index)
    addText(messageStream, flowDefinitions, "system"); // Add user prompt as text
    addText(messageStream, prompt); // Add user prompt as text

    // OpenAI API call
    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      messages: messageStream, 
    });

    console.log(chatCompletion); // Log the result
    return chatCompletion; // Return the response from OpenAI
  } catch (error) {
    console.error("There was an error fetching the data", error);
    return "An error occurred.";
  }
};





const convertNodesToString = (nodes) => {
  return nodes.map(node => {
    // Here, you can customize how you want to represent each node
    // For example, if nodes have 'id', 'type', and 'data' properties:
    return `Node ID: ${node.id}, Type: ${node.type}, Data: ${JSON.stringify(node.data)}`;
  }).join('\n');
};

export const getCompletionAnswer = (chatCompletion) => {
  return chatCompletion.choices[0].message.content;
}

export const getAnswerNoContext = async (prompt) => {
  try {
    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      messages: [
        {
          "role": "user",
          "content": prompt  // Use the provided prompt
        }
      ],
    });
    console.log(chatCompletion);
    return chatCompletion;  // Return the content string directly
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};


export const getAnswer = async (prompt, connectedNodes, projectMode = true) => {
  try {
    const { messages: messageStream } = generateMessages(connectedNodes);
    const flowDefinitions = projectMode ? GraphReasoning() : ContentCreationDefinitions();
    // Use helper functions to append messages
    addText(messageStream, flowDefinitions, "system"); // Add user prompt as text
    addText(messageStream, prompt);

    // OpenAI API call
    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      messages: messageStream, 
    });


    console.log(chatCompletion);
    return chatCompletion;  // Return the content string directly
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};

export const getSummary = async (prompt) => {
  try {
    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      messages: [
        {
          "role": "user",
          "content": "Summarize the main idea of each following paragraphs very simple terms:" + prompt
        }
      ],
    });

    console.log(chatCompletion);
    return chatCompletion;  // Return the content string directly
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};

export const getDevelopmement = async (prompt) => {

  const expertise = "Keyword Extraction, Text Summarization, Named Entity Recognition (NER), Topic Modeling, Dependency Parsing, Clustering"

  const instructions = `
  You are a highly skilled AI trained in ${expertise}.
  Please avoid unnecessary details or tangential points.
  Don't use complex wording. The simpler the better whil really make the topic clear. Make it like a little bulletpoint so it's quick to read.

  The bullets points will expain the topic by it'self.
  Each bulletpoint is one sentence of 5 to 10 words only.
  PLease add a sentence of each bullet point to explain how to do it or what it is (max 60 words)
  Your task is t create 5 to 10 bullet points.
  `;

  try {
    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      temperature: 0,
      messages: [
        {
          "role": "system",
          "content": instructions
        },
        {
          "role": "user",
          "content": prompt
        }
      ],
    });


    console.log(chatCompletion);
    return chatCompletion;  // Return the content string directly
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};


export const getProofRead = async (prompt) => {

  const expertise = "ProofReading"

  const instructions = `
  You are a highly skilled AI trained in ${expertise}.
  Please avoid unnecessary details or tangential points.
  The job is to make content as clear and as structures as possible without changing the meaning of it.
  Try to match as much as possible the style of the content.
  `;

  try {
    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      temperature: 0,
      messages: [
        {
          "role": "system",
          "content": instructions
        },
        {
          "role": "user",
          "content": prompt
        }
      ],
    });


    console.log(chatCompletion);
    return chatCompletion;  // Return the content string directly
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};

export const getMail = async (prompt) => {

  const expertise = "ProofReading, and efficient mail writing"

  const instructions = `
  You are a highly skilled AI trained in ${expertise}.
  
  The job is to make content as clear and as structured as possible without changing the meaning of it.
  Try to match as much as possible the style of the content.
  If necessary create  bullet points with eventually a short sentence that explain ecah point.
  
  Please avoid unnecessary details or tangential points.

  Task create an email easy to read slightly developping the content and generate a subject for it.
  `;

  try {
    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      temperature: 0,
      messages: [
        {
          "role": "system",
          "content": instructions
        },
        {
          "role": "user",
          "content": prompt
        }
      ],
    });

    console.log(chatCompletion);
    return chatCompletion;  // Return the content string directly
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};


export const getTitle = async (prompt) => {
  try {
    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      messages: [
        {
          "role": "system",
          "content": prompt  // Use the provided prompt
        },
        {
          "role": "user",
          "content": "Craft a Compelling 3-6 Word Title"
        }
      ],
    });

    console.log(chatCompletion);

    return chatCompletion;

  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};

export const getProject = async (project, levels, maxtasks) => {
  try {
    const prompt = getProjectPrompt(project, levels, maxtasks);  // Generate the prompt

    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      response_format: { "type": "json_object" },
      messages: [
        {
          "role": "user",
          "content": prompt  // Use the generated prompt
        }
      ],
    });
    console.log(chatCompletion);
    return chatCompletion;
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};

export const getKnowledge = async (project, levels, maxItems) => {
  try {
    const prompt = getKnowledgePrompt(project, levels, maxItems);  // Generate the prompt

    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      response_format: { "type": "json_object" },
      messages: [
        {
          "role": "user",
          "content": prompt  // Use the generated prompt
        }
      ],
    });

    console.log(chatCompletion);
    return chatCompletion; // Wrap the content in an array and return it
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};

export const getNotes = async (project, levels, maxItems) => {
  try {
    const prompt = getNotesPrompt(project, levels, maxItems);  // Generate the prompt

    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      response_format: { "type": "json_object" },
      messages: [
        {
          "role": "user",
          "content": prompt  // Use the generated prompt
        }
      ],
    });

    console.log(chatCompletion);
    return chatCompletion; // Wrap the content in an array and return it
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};



const createJsonNode = (title, isTask = false) => ({
  title,
  brief: "",
  children: [],
  ...(isTask && { type: 'task', state: 0 })
});

const parseIndentedText = (text) => {
  const lines = text.split('\n');
  const rootNode = createJsonNode('Root'); // Define a single root node
  const stack = [rootNode];

  // Detect the common indentation level
  const detectIndentationLevel = (line) => {
    const match = line.match(/^\s+/);
    return match ? match[0].length : 0;
  };

  let commonIndentation = 4; // Assume 4 spaces for a single indentation level
  let currentNode = rootNode;

  for (const line of lines) {
    if (!line.trim()) continue;

    const depth = detectIndentationLevel(line) / commonIndentation;
    const content = line.trim();

    if (content.startsWith(':template')) {
      continue;
    }

    if (content.startsWith(':')) {
      const titleContent = content.replace(':', '').trim();
      const isTask = titleContent.startsWith('>');
      const title = isTask ? titleContent.replace('>', '').trim() : titleContent;
      const newNode = createJsonNode(title, isTask);

      if (depth === 0) {
        rootNode.children.push(newNode);
        stack.length = 1; // Reset stack to only contain root
        stack.push(newNode);
      } else {
        while (stack.length > depth + 1) {
          stack.pop();
        }
        const parent = stack[stack.length - 1];
        parent.children.push(newNode);
        stack.push(newNode);
      }

      currentNode = newNode; // Update currentNode
    } else if (currentNode) {
      currentNode.brief += `${content.trim()}\n`;
    }
  }

  return rootNode;
};

export const nodesFromTemplate = async (inputText) => {
  const parsedData = parseIndentedText(inputText);
  const jsonData = JSON.stringify(parsedData, null, 2);
  return '[\n' + jsonData + '\n]';
};






export const transcribeAudio = async (audioBlob) => {
  try {
    // Convert the audio blob to a FormData object
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.wav'); // You might need to adjust the file name and type based on your actual audio format
    formData.append('model', 'whisper-1');

    // Make the API request to transcribe the audio
    const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${apiKey}`, // Use your API key here
      },
      body: formData
    });

    // Parse the JSON response
    const transcription = await response.json();
    return transcription.text; // Return the transcribed text
  } catch (error) {
    console.error('There was an error transcribing the audio', error);
    return "An error occurred during transcription.";
  }
};


export const textToSpeechBlob = async (text, voice = 'echo', model = 'tts-1-hd') => {
  try {
    // Define the payload for the POST request
    const payload = {
      model: model,  // or "tts-1" based on your requirement
      voice: voice,
      input: text,
    };

    // Make the API request to generate speech from text
    const response = await fetch('https://api.openai.com/v1/audio/speech', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${apiKey}`,  // Use your API key here
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    // Check for response.ok to ensure the request was successful
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Convert the response to a blob
    const blob = new Blob([await response.arrayBuffer()], { type: 'audio/mp3' });

    return blob;
  } catch (error) {
    console.error('Error generating speech:', error);
    throw error;
  }
}

export const textToImage = async (prompt, size = "1024x1024", fileName, canvasContext = '') => {
  // Check if fileName is provided
  if (!fileName) {
    console.error('Filename is required');
    return "Filename is required.";
  }

  // Concatenate prompt and canvasContext
  const finalPrompt = `${prompt} ${canvasContext}`.trim();

  try {
    const response = await openai.images.generate({
      model: "dall-e-3",
      prompt: finalPrompt,
      n: 1,
      size: size,
      response_format: "b64_json",
    });

    // Extract the base64 string from the response and convert it to a Blob
    const base64String = response.data[0].b64_json;
    const binaryData = atob(base64String);
    const arrayBuffer = new Uint8Array(binaryData.length);

    for (let i = 0; i < binaryData.length; i++) {
      arrayBuffer[i] = binaryData.charCodeAt(i);
    }

    const imageBlob = new Blob([arrayBuffer], { type: 'image/png' });

    // Upload the imageBlob directly to AWS S3 using uploadFiles
    const filesToUpload = [
      { file: imageBlob, key: fileName }
    ];

    const uploadResult = await uploadFiles(filesToUpload);

    // Check if the upload was successful and return the result
    if (uploadResult) {
      return { success: true, url: `/RECORD/images/${fileName}` }; // Assuming you want to return the URL
    } else {
      console.error('Error in file upload');
      return "An error occurred during file upload.";
    }
  } catch (error) {
    console.error('There was an error generating the image', error);
    return "An error occurred.";
  }
};

/*
export function convertGPTMarkdownToHTML({ text }) {
  return (
    { text }
  );
}
*/

export function convertGPTMarkdownToHTML(text) {
  // Convert Markdown to HTML
  const rawHtml = marked(text);

  // Sanitize HTML
  const safeHtml = DOMPurify.sanitize(rawHtml);

  // Return the sanitized HTML inside a div
  return safeHtml;
}



export function convertGPTMarkdownToHTML_deprecated_todelete(text) {
  // Replace bold markers
  let html = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Handle numbered lists, bullet points, and paragraphs
  const lines = html.split('\n');
  let inOrderedList = false;
  let inUnorderedList = false;
  let currentOrderedListIndex = 1;
  let htmlLines = lines.map(line => {
    if (/^\d+\./.test(line)) { // Numbered list
      if (!inOrderedList) {
        inOrderedList = true;
        currentOrderedListIndex = parseInt(line.match(/^\d+/)[0], 10); // Get the starting number
        line = `<ol start="${currentOrderedListIndex}"><li>` + line.substring(line.indexOf('.') + 1).trim() + '</li>';
      } else {
        line = '<li>' + line.substring(line.indexOf('.') + 1).trim() + '</li>';
      }
      if (inUnorderedList) {
        inUnorderedList = false;
        line = '</ul>' + line;
      }
      return line;
    } else if (/^- /.test(line)) { // Bullet points
      if (!inUnorderedList) {
        inUnorderedList = true;
        line = '<ul><li>' + line.substring(2).trim() + '</li>';
      } else {
        line = '<li>' + line.substring(2).trim() + '</li>';
      }
      return line;
    } else {
      if (inOrderedList) {
        inOrderedList = false;
        line = '</ol><p>' + line + '</p><br>';
      } else if (inUnorderedList) {
        inUnorderedList = false;
        line = '</ul><p>' + line + '</p><br>';
      } else {
        line = '<p>' + line + '</p><br>';
      }
      return line;
    }
  });

  // Join the lines back together
  html = htmlLines.join('');
  // Close any open list tags
  if (inOrderedList) html += '</ol>';
  if (inUnorderedList) html += '</ul>';

  return html;
}
